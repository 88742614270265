import { create } from 'zustand';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import { Meta, ProductSearchProps } from '../types';

interface SearchProductState {
  products: ProductSearchProps[] | any[];
  isLoading: boolean;
  isError: boolean;
  getSearchProduct: (params: any) => Promise<void>;
  meta: Meta;
}

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

export const prefix = '/products';

export const useSearchProducts = create<SearchProductState>((set) => ({
  products: [],
  isLoading: false,
  isError: false,
  meta: {
    pagination: {
      page: 1,
      pageSize: 5,
      pageCount: 1,
      total: 0,
    },
  },
  getSearchProduct: async (params?: AxiosRequestConfig) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(prefix, params);
      set({ products: response.data.data });
      set({ meta: response.data.meta });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching product:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
}));
