'use client';

import { ButtonProps } from '@/types';
import React, { FC, useMemo, useEffect, useState } from 'react';

export const Button: FC<ButtonProps<HTMLButtonElement>> = ({
  color,
  variant,
  size,
  className,
  ...props
}) => {
  const [hydrated, setHydrated] = useState(false);

  useEffect(() => {
    setHydrated(true);
  }, []);

  const sizes = useMemo(() => {
    let _size = size || 'sm';
    switch (_size) {
      case 'sm':
        return 'py-1 px-2';
      case 'md':
        return 'py-2 px-3';
      case 'lg':
        return 'py-2.5 px-4';
    }
  }, [size]);

  const colors = useMemo(() => {
    let colors = color || 'default';
    let _text = '';
    let _hoverText = '';
    let _background = '';
    let _hoverBackground = '';
    let _hoverOutline = '';
    let _border = '';
    switch (colors) {
      case 'default':
        _background = 'bg-white text-gray-800 border focus:outline-none';
        _hoverBackground = 'hover:bg-gray-50';
        _text = 'text-gray-800';
        _hoverText = 'hover:text-gray-900';
        _border = 'border-gray-200';
        break;
      case 'primary':
        _background = 'bg-primary text-gray-100 border focus:outline-none';
        _hoverBackground = 'hover:bg-primary-dark';
        _hoverOutline = 'hover:bg-primary-light';
        _text = 'text-primary';
        _hoverText = 'hover:text-primary-dark';
        _border = 'border-primary-dark';
        break;
      case 'secondary':
        _background = 'bg-secondary-500 text-gray-100';
        _hoverBackground = 'hover:bg-secondary-600';
        _hoverOutline = 'hover:bg-secondary-300';
        _text = 'text-secondary-500';
        _hoverText = 'hover:text-secondary-600';
        _border = 'border-secondary-500';
        break;
      case 'danger':
        _background = 'bg-error-500 text-gray-100';
        _hoverBackground = 'hover:bg-error-600';
        _hoverOutline = 'hover:bg-error-300';
        _text = 'text-error-500';
        _hoverText = 'text-error-600';
        _border = 'border-error-500';
        break;
      case 'warning':
        _background = 'bg-warning-500 text-gray-100';
        _hoverBackground = 'hover:bg-warning-600';
        _hoverOutline = 'hover:bg-warning-300';
        _text = 'text-warning-500';
        _hoverText = 'hover:text-warning-600';
        _border = 'border-warning-500';
        break;
    }
    return {
      background: _background,
      text: _text,
      border: _border,
      hoverBackground: _hoverBackground,
      hoverText: _hoverText,
      hoverOutline: _hoverOutline,
    };
  }, [color]);

  const variants = useMemo(() => {
    let _var = variant || 'default';
    switch (_var) {
      case 'default':
        return `${sizes} inline-flex items-center gap-x-1 font-medium rounded-lg border border-gray-200 disabled:opacity-50 disabled:pointer-events-none ${colors.background} ${colors.hoverBackground}${className ? ` ${className}` : ''}`;
      case 'solid':
        return `${sizes} inline-flex items-center gap-x-1 font-medium rounded-lg border disabled:opacity-50 disabled:pointer-events-none ${colors.background} ${colors.hoverBackground}${colors.border ? ` ${colors.border}` : ''}${className ? ` ${className}` : ''}`;
      case 'outline':
        return `${sizes} inline-flex items-center gap-x-1 font-medium rounded-lg border disabled:opacity-50 disabled:pointer-events-none${
          colors.text ? ` ${colors.text}` : ''
        }${colors.hoverOutline ? ` ${colors.hoverOutline}` : ''}${colors.border ? ` ${colors.border}` : ''}${className ? ` ${className}` : ''}`;
      case 'link':
        return `${sizes} inline-flex items-center gap-x-1 font-medium rounded-lg border border-transparent hover:underline disabled:opacity-50 disabled:pointer-events-none ${colors.text} ${colors.hoverText}${className ? ` ${className}` : ''}`;
      case 'custom':
        return `${colors.text} ${colors.hoverText}${className ? ` ${className}` : ''}`;
    }
  }, [variant, sizes, colors, className]);

  if (!hydrated) return null;

  return <button {...props} className={variants}></button>;
};
